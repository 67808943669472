import React, { useState } from "react"
import Axios from "axios"
import { navigate } from "gatsby"
import { Form, Button } from "react-bootstrap"
import Alert from "../alert"

const ForgotPassword = ({ endpoint, redirectURL, onSucess, validate }) => {
  const [state, setState] = useState({
    submitting: false,
    status: null,
  })

  const handleServerResponse = (ok, msg, form) => {
    setState({
      submitting: false,
      status: { ok, msg },
    })

    if (ok) {
      form.reset()
    }
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    const formElement = e.target
    const formData = new FormData(formElement)

    // @todo validation

    setState({ submitting: true })
    Axios({
      method: "post",
      url: endpoint,
      data: formData,
    })
      .then(({ data }) => {
        if (onSucess && onSucess instanceof Function) {
          onSucess(data)
        }

        if (redirectURL) {
          if (typeof window !== "undefined") {
            navigate(redirectURL, {
              redirectedFrom: "/forgot-password",
            })
          }
        }

        handleServerResponse(
          true,
          (data || {}).message || "Reset Link Sent!",
          formElement
        )
      })
      .catch(({ response }) => {
        handleServerResponse(
          false,
          (((response || {}).data || {}).errors || {}).email ||
            "Error in Resetting Password",
          formElement
        )
      })
  }

  return (
    <>
      {((state || {}).status || {}).ok !== undefined ? (
        <Alert
          type={((state || {}).status || {}).ok === true ? "success" : "danger"}
          title={
            ((state || {}).status || {}).ok === true
              ? "Reset Link Sent!"
              : "Password Reset Error"
          }
          message={((state || {}).status || {}).msg}
        />
      ) : null}
      <Form onSubmit={handleOnSubmit}>
        <fieldset disabled={(state || {}).submitting === true}>
          <p className="text-uppercase"> Provide your registered email </p>
          <Form.Group>
            <Form.Control
              name="email"
              type="email"
              placeholder="Email"
              required
              className="form-controls gray-input"
              size="lg"
            />
          </Form.Group>
          <Button
            type="submit"
            varient="primary"
            className="btn-d-border"
          >
            Reset Password
          </Button>
          <hr />
          <Button
            variant="secondary"
            className="btn-d-border round float-right"
            onClick={() => {
              if (typeof window !== "undefined") {
                navigate("/login", {
                  redirectedFrom: "/forgot-password",
                })
              }
            }}
          >
            Login
          </Button>

          <Button
            variant="warning"
            className="btn-d-border round mr-2 float-right"
            onClick={() => {
              if (typeof window !== "undefined") {
                navigate("/register", {
                  redirectedFrom: "/forgot-password",
                })
              }
            }}
          >
            Register for an Account
          </Button>
        </fieldset>
      </Form>
    </>
  )
}

export default ForgotPassword
