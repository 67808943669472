import React from "react"
import { Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../components/seo"
import ContainerWrapper from "../components/container"
import ForgotPasswordForm from "../components/partials/forgot-password"
import Alert from "../components/alert"

const ForgotPassword = ({ location }) => {
  const {
    site: {
      siteMetadata: { apiURL },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            apiURL
          }
        }
      }
    `
  )

  return (
    <div className="mb-6">
      <SEO title="Forgot Password" keywords={[`mazrui`, `careers`]} />
      <section className="section-password-reset mb-5 pb-5 mt-5 pt-5">
        <ContainerWrapper classNames="main" fluid>
          <ContainerWrapper className="login">
            <h2 className="text-center mt-0 page-title">Forgot Password</h2>
            <p className="text-center">
              <small id="password-help-text" className="text-muted small">
                An Email with reset link will be send your registered email
              </small>
            </p>
            {((location || {}).state || {}).msg && location.state.msg.length
              ? location.state.msg.map(m => (
                  <Alert type={m.type} message={m.body} title={m.title} />
                ))
              : null}
            <hr />
            <Row>
              <Col md={{ span: 6, offset: 3 }} sm={12}>
                <ForgotPasswordForm
                  endpoint={`${apiURL}/password/email` || undefined}
                />
              </Col>
            </Row>
          </ContainerWrapper>
        </ContainerWrapper>
      </section>
    </div>
  )
}

export default ForgotPassword
